import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { Configuration, SetConfiguration } from '../../../../master-data/configuration'
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import { CheckboxElement, FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui'
import { foundationTypes } from '../../../../master-data/foundation-types'
import { groundworkOptions } from '../../../../master-data/groundwork-options'
import { CustomerDataUserInput } from '../../customer-data'
import URI from 'urijs'
import { ShedRequest } from '../../../../master-data/shed-request'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
    submitFormTry: number
    customerDataUserInput: CustomerDataUserInput
    setCustomerDataUserInput: (customerDataUserInput: CustomerDataUserInput) => void
    setQuoteRequestIsBeingSent: (bool: boolean) => void
    renderScreenshotBlob: Blob | null
    makeScreenshotForQuoteRequestCallback: () => void
    iframeParentUrl: string | null
    thankYouPageUrl: string | null
}

export const CustomerData:FC<Props> = (props) => {
    const [formErrorMessage, setFormErrorMessage] = useState<null|string>(null)
    const submitButtonRef = useRef<HTMLButtonElement>(null)
    const [waitingForScreenshot, setWaitingForScreenshot] = useState(true)
    const [shedRequestIsSent, setShedRequestIsSent] = useState(false)
    const [viewShedRequestUrl, setViewShedRequestUrl] = useState<null|string>(null)
    const submittingTryRef = useRef(0)
    /**
     * Only used to prevent submit invalid form after submitting a valid one
     */
    const formErrorRef = useRef(false)

    useEffect(() => {
        if (props.submitFormTry > 0
            && submitButtonRef.current !== null
        ) {
            submitButtonRef.current.click()
        }
    }, [props.submitFormTry])


    /**
     * What a mess, but I can't think of anything better right now, and hey, as long as it works
     */
    useEffect(() => {
        window.setTimeout(() => {
            if (props.submitFormTry > 0
                && waitingForScreenshot
                && props.renderScreenshotBlob !== null
                && !formErrorRef.current
            ) {
                if (props.submitFormTry !== submittingTryRef.current) {
                    submittingTryRef.current = props.submitFormTry
                    actuallySubmitQuoteRequest()
                }
            }
        }, 100) // this timeout is needed to allow the formErrorRef to be set before submitting an invalid form
    }, [waitingForScreenshot, props.submitFormTry, props.renderScreenshotBlob])

    const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        props.setCustomerDataUserInput({
            ...props.customerDataUserInput,
            [event.target.name]: event.target.value
        })
    }

    /**
     * Called when someone submits the form, meaning the form input is valid
     */
    const handleFormSubmit = async () => {
        formErrorRef.current = false
        setWaitingForScreenshot(true)
        props.setQuoteRequestIsBeingSent(true)
        props.makeScreenshotForQuoteRequestCallback()
    }

    const handleFormError = () => {
        formErrorRef.current = true
    }

    const actuallySubmitQuoteRequest = async () => {
        try {
            setFormErrorMessage(null)
            // upload file first
            const filesApiUrl = import.meta.env.VITE_DUBLO_PUBLIC_API_URL + '/files'
            const formData = new FormData()
            formData.append('title', 'configuration-screenshot.png')
            formData.append('folder', import.meta.env.VITE_PUBLIC_API_SHED_SCREENSHOTS_FOLDER_UUID)
            formData.append('file', props.renderScreenshotBlob as Blob)
            const fileUploadResponse = await fetch(filesApiUrl, {
                method: 'POST',
                body: formData
            })

            const fileUploadResponseJson = await fileUploadResponse.json()
            if (!fileUploadResponse.ok) {
                throw new Error('Error while uploading render screenshot')
            }
            const fileUuid = fileUploadResponseJson.data.id

            const body:ShedRequest = {
                shed_screenshot: fileUuid,
                foundation_type: props.customerDataUserInput.foundationType,
                groundwork: props.customerDataUserInput.groundwork,
                configuration_json: JSON.stringify(props.configuration),
                customer_name_or_company_name: props.customerDataUserInput.nameOrCompanyName,
                customer_contact_person: props.customerDataUserInput.contactPerson,
                customer_email: props.customerDataUserInput.email,
                customer_telephone: props.customerDataUserInput.telephone,
                customer_street_and_house_number: props.customerDataUserInput.streetAndHouseNumber,
                customer_zip: props.customerDataUserInput.zip,
                customer_city: props.customerDataUserInput.city,
                customer_notes: props.customerDataUserInput.notes,
                customer_agreed_with_terms_and_conditions: props.customerDataUserInput.agreedWithTermsAndConditions,
                requested_from_url: props.iframeParentUrl !== null ? props.iframeParentUrl : window.location.href
            }

            const apiUrl = import.meta.env.VITE_DUBLO_PUBLIC_API_URL + '/items/shed_requests'
            const response = await fetch(apiUrl, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(body)
            })

            if (response.status === 200) {
                if (props.thankYouPageUrl !== null) {
                    if (props.iframeParentUrl !== null) {
                        parent.postMessage({
                            redirectToUrl: props.thankYouPageUrl
                        }, '*')
                    } else {
                        window.location.href = props.thankYouPageUrl
                    }
                } else {
                    setShedRequestIsSent(true)
                    const responseJson = await response.json()
                    const uri = new URI(responseJson.data.requested_from_url)
                    uri.setQuery({ 'shed-request-uuid': responseJson.data.id })
                    const viewConfigurationUrl = uri.toString()
                    setViewShedRequestUrl(viewConfigurationUrl)
                }
            } else {
                setFormErrorMessage('Er is helaas iets fout gegaan tijdens het versturen van uw aanvraag, probeer het nogmaals of neem contact met ons op.')
            }
        } catch (error) {
            console.error(error)
            setFormErrorMessage('Er is helaas iets fout gegaan tijdens het versturen van uw aanvraag, probeer het nogmaals of neem contact met ons op.')
        } finally {
            props.setQuoteRequestIsBeingSent(false)
            //actuallySubmittingQuoteRequestRef.current = false
        }
    }

    const closeShedRequestIsSentDialogHandler = () => {
        setShedRequestIsSent(false)
    }

    const closeFormErrorDialogHandler = () => {
        setFormErrorMessage(null)
    }

    return <>
        <Dialog
            open={shedRequestIsSent}
            onClose={closeShedRequestIsSentDialogHandler}
        >
            <DialogContent>
                <Typography>Bedankt, uw aanvraag is verstuurd. U ontvangt op het door u opgegeven e-mailadres een bevestiging.</Typography>
                {viewShedRequestUrl !== null &&
                    <>
                        <Typography marginTop={1}>Middels <a style={{color: 'inherit'}} target="_blank" href={viewShedRequestUrl}>deze link</a> kun je geconfigureerde DUBLO bekijken en delen met anderen.</Typography>
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button autoFocus color="primary" variant="contained" onClick={closeShedRequestIsSentDialogHandler}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={formErrorMessage !== null}
            onClose={closeFormErrorDialogHandler}
        >
            <DialogContent>
                <Typography color="error">{formErrorMessage}</Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus color="primary" variant="contained" onClick={closeFormErrorDialogHandler}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>

        <Typography variant="h5" gutterBottom>
            Uw gegevens
        </Typography>
        <Typography>
            Tevreden met je DUBLO?<br />
            Vul hieronder je gegevens in en binnen één werkdag ontvang je een reactie van ons.
        </Typography>
        <Box component="div" sx={{ marginTop: 2 }}>
            <FormContainer onSuccess={handleFormSubmit} onError={handleFormError} defaultValues={props.customerDataUserInput}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextFieldElement
                            value={props.customerDataUserInput.nameOrCompanyName}
                            label="Naam of bedrijfsnaam"
                            fullWidth
                            name="nameOrCompanyName"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldElement
                            value={props.customerDataUserInput.contactPerson}
                            label="Contactpersoon"
                            fullWidth
                            name="contactPerson"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldElement
                            value={props.customerDataUserInput.email}
                            parseError={error => {
                                switch (error.type) {
                                    case 'required':
                                        return 'Is verplicht'
                                    case 'pattern' :
                                        return 'Is geen gelding e-mailadres'
                                }
                            }}
                            label="E-mailadres"
                            required
                            fullWidth
                            name="email"
                            type="email"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldElement
                            value={props.customerDataUserInput.telephone}
                            parseError={() => {
                                return 'Is verplicht'
                            }}
                            label="Telefoonnummer"
                            required
                            fullWidth
                            name="telephone"
                            type="tel"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldElement
                            value={props.customerDataUserInput.streetAndHouseNumber}
                            label="Straat en huisnummer"
                            fullWidth
                            name="streetAndHouseNumber"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldElement
                            value={props.customerDataUserInput.zip}
                            label="Postcode"
                            fullWidth
                            name="zip"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldElement
                            value={props.customerDataUserInput.city}
                            parseError={() => {
                                return 'Is verplicht'
                            }}
                            label="Woonplaats"
                            required
                            fullWidth
                            name="city"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldElement
                            value={props.customerDataUserInput.notes}
                            label="Opmerkingen"
                            fullWidth
                            multiline
                            minRows={3}
                            name="notes"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectElement
                            value={props.customerDataUserInput.foundationType}
                            label="Fundering"
                            fullWidth
                            name="foundationType"
                            options={foundationTypes.map(foundationType => {
                                return {
                                    id: foundationType.key,
                                    label: foundationType.title
                                }
                            })}
                            onChange={(newValue) => {
                                props.setCustomerDataUserInput({
                                    ...props.customerDataUserInput,
                                    foundationType: newValue
                                })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectElement
                            value={props.customerDataUserInput.foundationType}
                            label="Grondwerk"
                            fullWidth
                            name="groundwork"
                            options={groundworkOptions.map(groundworkOption => {
                                return {
                                    id: groundworkOption.key,
                                    label: groundworkOption.title
                                }
                            })}
                            onChange={(newValue) => {
                                props.setCustomerDataUserInput({
                                    ...props.customerDataUserInput,
                                    groundwork: newValue
                                })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckboxElement
                            label={<>Ik ga akkoord met de <a style={{ color: 'inherit' }} target="blank" href="https://dublo.nl/algemene-voorwaarden">algemene voorwaarden</a></>}
                            required
                            name="agreedWithTermsAndConditions"
                            parseError={() => {
                                return 'U dient akkoord te gaan met de algemene voorwaarden'
                            }}
                            checked={props.customerDataUserInput.agreedWithTermsAndConditions}
                            onChange={(event, checked) => {
                                props.setCustomerDataUserInput({
                                    ...props.customerDataUserInput,
                                    agreedWithTermsAndConditions: checked
                                })
                            }}
                        />
                    </Grid>
                </Grid>
                <Box component="div" sx={{ display: 'none' }}>
                    <button ref={submitButtonRef} type="submit">submit</button>
                </Box>
            </FormContainer>
        </Box>
    </>
}
